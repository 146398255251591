import React from 'react';
import { SEO } from "../components";
import {  Link } from "gatsby";
import { 
     Button,
     Purchase
  } from "../styles/components";


function gracias(props) {
    return (
        <div>
            <SEO title='Compra Exitosa' />
            <Purchase>
                <h2>Compra Exitosa</h2>
                <p>Espero que disgfrutes tu swag. lucelo con orgullo</p>
                <p>Te esperamos de vuelta no pare de aprender</p>
                <span role='img' aria-label='donut'>⛑</span>
            
                <Link to='/' >
                    <Button>Volver al Catálogo</Button>
                </Link>
            </Purchase>
        </div>
    );
}

export default gracias;